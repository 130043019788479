<template>
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <!--begin::Table-->

          <base-table
            ref="table"
            :columns="columns"
            :service="'person'"
            :defaultFilter="searchParams"
          >
            <template #header>
              <div class="row">
                <!-- <div class="col-auto">
                  <div class="btn-group" role="group">
                    <button
                      id="btnGroupDrop1"
                      type="button"
                      class="btn btn-dark font-weight-bold dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Action
                    </button>
                    <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                      <button @click="batchAction" class="dropdown-item">
                        Ajukan KTA
                      </button>
                    </div>
                  </div>
                </div> -->
                <div class="col">
                  <select
                    v-model="searchParams.dimension"
                    class="form form-control"
                    placeholder="Search..."
                  >
                    <option value="">Pilih Matra</option>
                    <option
                      v-for="(item, index) in matraOptions"
                      :key="index"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </option>
                  </select>
                </div>
                <div class="col">
                  <select
                    v-model="searchParams.rank"
                    class="form form-control"
                    placeholder="Search..."
                  >
                    <option value="">Pilih Pangkat</option>
                    <option
                      v-for="(item, index) in pangkatOptions"
                      :key="index"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </option>
                  </select>
                </div>
                <div class="col">
                  <select
                    v-model="searchParams.status"
                    class="form form-control"
                    placeholder="Search..."
                  >
                    <option
                      v-for="(option, key) in statusOptions"
                      :key="key"
                      :value="option.value"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </div>
                <div class="col">
                  <input
                    type="text"
                    name="search"
                    placeholder="Search..."
                    class="form form-control"
                    v-model="searchParams.search"
                  />
                </div>
                <div class="col-auto">
                  <button class="btn btn-primary" @click="$refs.table.reload()">
                    Filter
                  </button>
                  <button
                    class="btn btn-secondary"
                    @click="$router.push({ name: 'submission-kta-upload' })"
                  >
                    Import
                  </button>
                </div>
              </div>
            </template>

            <template #action-button="{ data }">
              <!-- <button
                class="btn btn-success btn-sm"
                @click="
                  $router.push({
                    name: 'verifikasi',
                    params: { personId: data.value.id },
                  })
                "
              >
                Verifikasi
              </button> -->
              <button
                class="btn btn-primary btn-sm"
                @click="
                  $router.push({
                    name: 'person-detail',
                    params: { personId: data.value.id },
                  })
                "
              >
                Lihat Detail
              </button>
              <button
                class="btn btn-success btn-sm"
                @click="handleActionButton(data.value)"
              >
                Ajukan KTA
              </button>
            </template>
          </base-table>

          <!--end::Table-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import { toRaw } from "vue";
import BaseTable from "@/components/BaseTable.vue";
import Service from "@/services/base.service";

export default {
  components: {
    BaseTable,
  },
  data() {
    return {
      searchParams: {
        search: "",
        status: "",
        rank: "",
        dimension: "",
      },
      statusOptions: [
        { label: "Semua", value: "" },
        { label: "Belum Verifikasi", value: "UPLOADED" },
        { label: "Terverifikasi", value: "VERIFIED" },
      ],
      file: "",
      columns: [
        // { field: "id", title: "ID", isUnique: true, type: "number" },
        // { field: "card_type.name", title: "Jenis KTA" },
        { field: "nama_lengkap", title: "Nama" },
        { field: "dimension.name", title: "Matra" },
        { field: "rank.name", title: "Pangkat/Korps" },
        { field: "nip_nrp", title: "NRP/NBI/NIP" },
        { field: "position.name", title: "Jabatan" },
        { field: "unity.name", title: "Kesatuan" },
        { field: "createdAt", title: "Tanggal Pengajuan" },
        { field: "status", title: "Status" },
        { field: "actions", title: "Actions" },
      ],
      matraOptions: [],
      pangkatOptions: [],
      jabatanOptions: [],
      kesatuanOptions: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const matraService = new Service("master/matra");
      const pangkatService = new Service("master/pangkat");
      const jabatan = new Service("master/jabatan");
      const kesatuan = new Service("master/kesatuan");

      const [matraData, pangkatData, jabatanData, kesatuanData] =
        await Promise.all([
          matraService.getListData(),
          pangkatService.getListData(),
          jabatan.getListData(),
          kesatuan.getListData(),
        ]);

      this.matraOptions = matraData.data.map((r) => {
        return { value: r.id, label: r.name };
      });
      this.pangkatOptions = pangkatData.data.map((r) => {
        return { value: r.id, label: r.name };
      });
      this.jabatanOptions = jabatanData.data.map((r) => {
        return { value: r.id, label: r.name };
      });
      this.kesatuanOptions = kesatuanData.data.map((r) => {
        return { value: r.id, label: r.name };
      });
    },
    handleUploadFile() {
      this.file = this.$refs.file.files[0];
    },
    async submitFileUpload() {
      let formData = new FormData();
      formData.append("file", this.file);
      const service = new Service("attachment/upload");
      try {
        const { data } = await service.uploadFile(formData);
        const { id } = data;
        const serviceProcess = new Service(`attachment/process/${id}`);
        const resultProcess = await serviceProcess.createData({
          task: "import-personil",
          do: ["submit-cardApplication"],
        });
        console.log("resultProcess", resultProcess);
        this.$refs.file.value = null;
      } catch (error) {
        console.log(error);
      }
    },
    async confirmAJukan(selectedData) {
      await this.$swal
        .fire({
          title: "Ajukan KTA",
          html: '<input ref="kta_number" name="kta_number" id="swal-input1" class="form form-control" placeholder="Nomor KTA">',
          // '<input ref="card_type_name" name="card_type_name" id="swal-input2" class="form form-control" placeholder="Jenis Kartu">',
          showCancelButton: true,
          confirmButtonText: "Ajukan",
          showLoaderOnConfirm: true,
          allowOutsideClick: () => !this.$swal.isLoading(),
          preConfirm: async () => {
            const kta_number = document.querySelector(
              "input[name=kta_number]"
            ).value;
            const card_type_name = "KTA";
            /*document.querySelector(
              "input[name=card_type_name]"
            ).value;*/
            if (!kta_number || !card_type_name) {
              this.$swal.showValidationMessage(
                "Nomor KTA dan Jenis KTA Wajib diisi!"
              );
            } else {
              try {
                const cardApplicationService = new Service("card-application");
                const cardApplicationParam = {
                  kta_number: kta_number,
                  person_id: selectedData.id,
                  card_type_name: card_type_name,
                };
                return await cardApplicationService.createData(
                  cardApplicationParam
                );
              } catch (error) {
                console.log(error);
              }
            }
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            const { data, message } = result.value;
            this.$swal.fire({
              icon: "success",
              title: message,
              message: `Berhasil ${data.status} ID ${data.id}`,
            });
          }
        });
    },
    handleActionButton(value) {
      const selectedData = toRaw(value);
      this.confirmAJukan(selectedData);
    },
    batchAction() {
      const data = this.$refs.table.getSelectedRows();
      const ids = data.map((r) => {
        return r.id;
      });
      if (ids.length < 1) {
        this.$swal.fire({
          title: "Warning!",
          text: "Tidak ada data dipilih",
          icon: "info",
        });
        return;
      }
      this.$swal
        .fire({
          title: `${ids.length} Data Dipilih`,
          text: "Yakin akan mengajukan KTA",
          icon: "warning",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.doRequestApproval(ids);
          }
        });
    },
    doRequestApproval(ids) {
      ids.forEach((e) => {
        console.log(e);
      });
    },
  },
};
</script>
